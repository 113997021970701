import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

//lib
import PropagateLoader from "react-spinners/PropagateLoader";
import Web3 from "web3";
import { toastAlert } from "../helper/toastAlert.js";

//config 
import config from "../config/index.js";

//ABI 
import contractAbi from "../ABI/ContractABI.json";
import TokenABI from "../ABI/TokenABI.json";

//component 
import Legcount from "../components/Legcount.js";
import Treeview from "../components/Treeview.js";
import DashboardDetail from "../components/DashboardDetails.js";


//basic component 
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};
let single_digit = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

export default function Dashboard(props) {
  const walletConnection = useSelector((state) => state?.walletConnection);
  const [loader, setLoader] = useState(false);
  const [poolinfo, setpoolinfo] = useState([]);
  const [referraldata, setreferraldata] = useState([]);


  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("wagmi.store")).state.data.account == null
    ) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    getpooldetail()
  }, [walletConnection?.address])


  //get pool info  fun.
  const getpooldetail = async () => {
    if (walletConnection.address) {

      if (!walletConnection?.userdetails?.isExist) {
        window.location.href = "/"
      }

      var web3 = new Web3(walletConnection?.web3);
      var contractcall = new web3.eth.Contract(
        contractAbi,
        config.Contract
      );
      let poollength = await contractcall.methods.getPoolLenght().call();
      let referralUser = await contractcall.methods.viewUserRefaral(walletConnection.address).call();
      setreferraldata(referralUser)
      const poolarr = []
      let isdisablethree = false
      let isdisablesix = false
      setLoader(true)
      for (let i = 0; i < poollength; i++) {
        let pooldata = await contractcall.methods.poolInfo(i).call();
        let poolinfodata = await contractcall.methods.userpool(walletConnection.address, i).call();

        poolarr.push({
          poolNo: i, cappingAmount: Number(pooldata.cappingAmount / 1e18), poolAmount: Number(pooldata.poolAmount / 1e18), isexits: poolinfodata.cappingAmount / 1e18 > 0, totalinc: poolinfodata.totInc / 1e18, isdisablethree: isdisablethree, isdisablesix: isdisablesix
        })
        if (i == 2) {
          if (walletConnection?.userdetails?.totalInc / 1e18 <= pooldata.cappingAmount / 1e18) {
            isdisablethree = true
          }
        }
        if (i == 5) {
          if (walletConnection?.userdetails?.totalInc / 1e18 <= pooldata.cappingAmount / 1e18) {
            isdisablethree = true
          } else {
            isdisablethree = false
          }
        }
      }

      setLoader(false)
      setpoolinfo(poolarr)
    }
  }


  // Upgrade plan fun.
  const UpgradePlan = async (poolid) => {
    try {
      if (walletConnection.address) {
        setLoader(true)

        var web3 = new Web3(walletConnection?.web3);
        const ContractToken = new web3.eth.Contract(
          TokenABI,
          config.TokenContract
        );
        var contractcall = new web3.eth.Contract(
          contractAbi,
          config.Contract
        );

        let firstPlan = await contractcall.methods.poolInfo(poolid).call();
        let planAmount = Number(firstPlan.poolAmount) / config.decimalVal;

        let tokenBal = await ContractToken.methods
          .balanceOf(walletConnection.address)
          .call();

        if (Number(planAmount) > Number(tokenBal / 1e18)) {
          toastAlert("error", "Your USDT balance is low");
          setLoader(false)
          return false;
        }

        let estimateGas = await ContractToken.methods
          .approve(config.Contract, (planAmount * 1e18).toString())
          .estimateGas({ from: walletConnection.address })
          .catch((err) => console.log(err));

        let gasPriceUsdt = await web3.eth.getGasPrice();
        let estimateGasFee = (estimateGas * gasPriceUsdt) / 1e18;


        var balance = await web3.eth.getBalance(walletConnection.address);

        if (estimateGasFee > balance / 1e18) {
          toastAlert("error", "Your BNB balance is low");
          setLoader(false)

          return false;
        }

        let currentplan = walletConnection.userdetails?.userCurrentPlan
        if (currentplan != poolid - 1) {
          toastAlert("error", `Please Upgrade Package ${Number(currentplan) + 2} `);
          setLoader(false)

          return false;
        }

        setLoader(true)
        await ContractToken.methods.approve(config.Contract, (planAmount * 1e18).toString()).send({ from: walletConnection.address }).then(async (res) => {


          await contractcall.methods.reJoinPlan(poolid).send({ from: walletConnection.address }).then(async (res) => {
            toastAlert("success", "Upgrade Successfully");
            setTimeout(() => {
              window.location.reload()
            }, 1500);
            setLoader(false)
          })
        })
      }
    } catch (err) {
      console.log(err);
      toastAlert("error", "Transaction Rejected");
      setLoader(false)

    }
  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="inner_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="inner_head_title">Dashboard</h2>
            </div>
          </div>
          <div className="row align-items-center dash_height_row">
            <div className="col-lg-12">
              <DashboardDetail referraldata={referraldata} />
            </div>
            <div className="col-lg-12">
              <div class="accordion dashboard_accordian" id="dash_accordion">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="29"
                        viewBox="0 0 26 29"
                        fill="none"
                      >
                        <path
                          d="M5.14938 12.9036C5.14938 10.612 3.29165 8.75427 1 8.75427M5.14938 4.5304C5.14938 2.8403 3.7793 1.47023 2.08921 1.47023M19.8506 12.9036C19.8506 10.612 21.7084 8.75427 24 8.75427M19.8506 4.53038C19.8506 2.84029 21.2207 1.47021 22.9108 1.47021M18.0819 15.6007V27.5302M18.0819 15.6007H3.282L1.16499 19.387H15.9649L18.0819 15.6007ZM18.0819 15.6007H22.2832L24.4002 19.387H20.1989L18.0819 15.6007ZM18.0819 27.5302H3.26561V19.4389M18.0819 27.5302H22.2832V19.4908M14.7794 8.52087L12.7825 6.52398L10.7857 8.52087M10.3261 12.4638H15.0315M17.8025 7.65385C17.8025 4.82413 15.5086 2.5302 12.6788 2.5302C9.84913 2.5302 7.55519 4.82413 7.55519 7.65385C7.55519 9.63539 8.68014 11.354 10.3261 12.2063V15.6007H15.0315V12.2063C16.6775 11.354 17.8025 9.63539 17.8025 7.65385Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Subscription Packages
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#dash_accordion"
                  >
                    <div class="accordion-body">
                      <div className="packages_panel">
                        {poolinfo && poolinfo.map((item, index) => {
                          return (
                            <div className={`packages_div packages_div_${single_digit[index]} ${item.isexits ? "active_package" : ""} ${item.isdisablethree ? "package_diasbled" : ""}`}>
                              <div className="packages_div_inner">
                                <div className="package_details">
                                  <h3>Package {item.poolNo + 1}</h3>
                                  <h4>
                                    {item.poolAmount} <span>USDT</span>
                                  </h4>
                                </div>
                                {item.isexits ?
                                  <>
                                    <div class="progress">
                                      <div
                                        class="progress-bar progress-bar-striped progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="50"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{ width: `${Math.floor((item.totalinc / item.cappingAmount) * 100)}%` }}
                                      ></div>
                                    </div>
                                    <div className="progress_info">
                                      <p>Capping Percentage {Math.floor((item.totalinc / item.cappingAmount) * 100)}%</p>
                                      <p>
                                        {item.totalinc} <span>/{item.cappingAmount} USDT</span>
                                      </p>
                                    </div>

                                    <Link to={`/dashboard-detail/${item.poolNo}`} class="primary_btn">
                                      <span class="text">Team & Income Details</span>
                                    </Link>
                                  </>
                                  :
                                  <>
                                    <div className="capping_div">
                                      <h3>Capping</h3>
                                      <h4>
                                        {item.cappingAmount} <span>USDT</span>
                                      </h4>
                                    </div>
                                    <button class="primary_btn" onClick={() => UpgradePlan(item.poolNo)}
                                      disabled={loader}
                                    >
                                      <span class="text">Upgrade</span>
                                    </button>
                                  </>}
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                      >
                        <path
                          d="M13.5 6.18672C14.9322 6.18672 16.0933 5.02562 16.0933 3.59336C16.0933 2.1611 14.9322 1 13.5 1C12.0677 1 10.9066 2.1611 10.9066 3.59336C10.9066 5.02562 12.0677 6.18672 13.5 6.18672ZM13.5 6.18672C11.2083 6.18672 9.3506 8.04444 9.3506 10.3361V11.8921H17.6494V10.3361C17.6494 8.04444 15.7916 6.18672 13.5 6.18672ZM5.14938 20.2946C6.58164 20.2946 7.74274 19.1335 7.74274 17.7012C7.74274 16.269 6.58164 15.1079 5.14938 15.1079C3.71712 15.1079 2.55602 16.269 2.55602 17.7012C2.55602 19.1335 3.71712 20.2946 5.14938 20.2946ZM5.14938 20.2946C2.85773 20.2946 1 22.1523 1 24.444V26H9.29876V24.444C9.29876 22.1523 7.44103 20.2946 5.14938 20.2946ZM13.5 11.8921V18.1681L18.4226 22.1061M8.57738 22.1061L13.5 18.1681M24.4439 17.7012C24.4439 19.1335 23.2828 20.2946 21.8506 20.2946C20.4183 20.2946 19.2572 19.1335 19.2572 17.7012C19.2572 16.269 20.4183 15.1079 21.8506 15.1079C23.2828 15.1079 24.4439 16.269 24.4439 17.7012ZM26 26H17.7013V24.444C17.7013 22.1523 19.559 20.2946 21.8507 20.2946C24.1423 20.2946 26 22.1523 26 24.444V26Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      My Tree
                    </button>
                  </h2>
                  <Treeview />
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="28"
                        viewBox="0 0 39 28"
                        fill="none"
                      >
                        <path
                          d="M14.7117 1.45361H10.1667V3.72648M23.8031 1.45361H28.3481V3.72648M10.1667 24.1807V26.4536H14.7117M28.3481 24.1807V26.4536H23.8031M7.89387 8.2722C7.89387 8.57071 7.83505 8.86628 7.72077 9.14205C7.6065 9.41781 7.439 9.66835 7.22786 9.87936C7.01671 10.0904 6.76606 10.2577 6.49022 10.3718C6.21438 10.4859 5.91877 10.5445 5.62026 10.5443C4.36534 10.5443 3.27214 9.52712 3.27214 8.2722C3.27214 7.01728 4.36534 5.99934 5.62026 5.99934C5.91883 5.99914 6.21451 6.05779 6.49039 6.17194C6.76628 6.28608 7.01697 6.45348 7.22812 6.66456C7.43927 6.87565 7.60675 7.12628 7.72099 7.40213C7.83522 7.67798 7.89397 7.97363 7.89387 8.2722ZM10.166 17.3629H1C1 14.8523 3.11115 12.8172 5.62025 12.8172C6.2172 12.8172 6.80831 12.9348 7.35982 13.1632C7.91133 13.3916 8.41245 13.7265 8.83456 14.1486C9.25667 14.5707 9.59151 15.0718 9.81995 15.6233C10.0484 16.1748 10.166 16.766 10.166 17.3629ZM35.242 8.2722C35.242 9.52712 34.1488 10.5443 32.8939 10.5443C32.2911 10.5443 31.713 10.3049 31.2867 9.87861C30.8605 9.45237 30.621 8.87426 30.621 8.27146C30.621 7.66866 30.8605 7.09054 31.2867 6.6643C31.713 6.23805 32.2911 5.99859 32.8939 5.99859C34.1488 5.99934 35.242 7.01654 35.242 8.2722ZM37.5148 17.3629H28.3489C28.3489 16.1573 28.8278 15.0011 29.6803 14.1486C30.5328 13.2961 31.689 12.8172 32.8946 12.8172C35.4037 12.8172 37.5148 14.8523 37.5148 17.3629ZM22.6667 9.40789C22.6667 11.2908 21.1403 12.8172 19.2574 12.8172C17.3745 12.8172 15.8481 11.2908 15.8481 9.40789C15.8481 7.52499 17.3745 5.99859 19.2574 5.99859C21.1403 5.99859 22.6667 7.52499 22.6667 9.40789ZM24.9388 21.9079H13.5753V20.7715C13.5924 19.2758 14.1986 17.8473 15.2622 16.7957C16.3259 15.7441 17.7613 15.1544 19.257 15.1544C20.7528 15.1544 22.1882 15.7441 23.2519 16.7957C24.3155 17.8473 24.9217 19.2758 24.9388 20.7715V21.9079Z"
                          stroke="#0056FF"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Leg Count
                    </button>
                  </h2>
                  <Legcount />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loader && (
          <div className="loadBefore">
            <PropagateLoader
              color={"#72d438"}
              loading={loader}
              cssOverride={override}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        )
      }
      <Footer />
    </div >
  );



}
